body {
  height: 100vh;
  background-color: #fff;
}

#root {
  height: 100%;
  font-family: system-ui, sans-serif;
  font-weight: 200;
}

.app-container {
  width: 100%;
  height: 100%;
}

.buttons {
  height: 8%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.buttons button {
  height: 40px;
}

.container {
  height: 92%;
}

.forms-container {
  height: 92%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

.forms-question {
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 2%;
}

.forms-question input {
  width: 35px;
  margin-left: 5px;
}

.forms {
  width: 95%;
  flex-flow: wrap;
  justify-content: flex-start;
  display: flex;
}

.form {
  background-color: #f0f8ff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  margin: .5%;
  padding: 1%;
  display: flex;
}

.form-label {
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  display: flex;
}

.form-input {
  background: none;
  border: none;
  border-bottom: 1px solid #0e2b30;
  outline: none;
}

.form-button {
  color: #f0f8ff;
  cursor: pointer;
  background-color: #091517;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  padding: 1%;
}

.container {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.container .form {
  height: 50vh;
  width: 85%;
  background-color: #111f21;
  border-radius: 35px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.container .form .input {
  color: #fff;
}

input[type="file"] {
  border-radius: 20px;
}

p {
  color: #fff;
  text-align: center;
  letter-spacing: .1px;
  font-size: larger;
  font-weight: 500;
}

ul {
  list-style-type: none;
}

li {
  color: #fff;
  font-size: 10px;
}

li a:visited {
  color: green;
}

.data-container {
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  display: flex;
}

.data-left {
  font-size: 14px;
}

/*# sourceMappingURL=index.1335f41d.css.map */
