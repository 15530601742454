body{
    height: 100vh;
    background-color: #FFF;
    
}

#root{
    height: 100%;
    font-family: system-ui, sans-serif;
    font-weight: 200;
}
/*App*/
.app-container{
    width: 100%;
    height: 100%;
}
.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 8%;
}
.buttons button {
    height: 40px;
}
/*--------------*/
/*Forms request*/
.container{
    height: 92%;
}
.forms-container{
    height: 92%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.forms-question{
    padding: 2%;
    background-color: aliceblue;
    border-radius: 10px;
}
.forms-question input {
    margin-left: 5px;
    width: 35px;
}
.forms{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 95%;
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.5%;
    background-color: aliceblue;
    border-radius: 10px;
    padding: 1%;

}
.form-label{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0px;
}
.form-input{
    border: none;
    background: none;
    border-bottom: solid 1px #0e2b30;
    outline: none;
}
.form-button{
    margin-top: 5px;
    background-color: #091517;
    padding: 1%;
    color: aliceblue;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

/*-------------*/
/*Batch Request */

.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.container .form{
    height: 50vh;
    width: 85%;
    background-color: #111f21;

    border-radius: 35px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.container  .form .input{
    color: white;
}
input[type=file] {
    border-radius: 20px;
}

p{
    color: #FFF;
    text-align: center;
    font-size: larger;
    font-weight: 500;
    letter-spacing: 0.1px;
}
ul{
    list-style-type: none;
}
li{
    color: white;
    font-size: 10px;
}
li a:visited{
    color: green;
}

.data-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 95%;
}
.data-left{
    font-size: 14px;
}
/*----------------*/
